import React, { useState } from "react";
import "./questionnaire.css";

const Questionnaire = () => {
  // État pour suivre la question actuelle et les réponses
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Liste des questions avec leurs labels de slider et messages de feedback
  const questions = [
    {
      question: "Quand ton dialogue intérieur devient négatif, quelle stratégie utilises-tu ?",
      labels: ["Auto-critique", "Évitement", "Discussion amicale", "Recadrage", "Action"],
      feedback: [
        "Se juger sévèrement peut aggraver les choses. Essaye de te demander : 'En quoi cette pensée m'est utile ?' ou 'Que dirais-je à un ami dans cette situation ?'",
        "Éviter les pensées négatives donne un soulagement temporaire, mais ne les résout pas. Pour une solution durable, écris tes pensées puis brûle ou déchire la page symboliquement pour t'en libérer.",
        "Dialoguer avec soi-même avec bienveillance est une excellente stratégie ! La prise de recul et l'humour sont des alliés précieux pour relativiser.",
        "Recadrer tes pensées négatives est une très bonne chose ! Cela te permet de voir les choses sous un autre angle et de relativiser. Fais juste attention à ne pas tomber dans le déni : reconnais tes émotions avant de les transformer.",
        "Passer à l'action est une excellente stratégie pour sortir de la rumination. Cela te permet de te concentrer sur des solutions concrètes. N'oublie pas qu'il est parfois tout aussi important de prendre du temps pour ressentir tes émotions avant d'agir."
      ],
    },
    {
      question: "Comment réagis-tu face à des pensées intrusives ou répétitives ?",
      labels: ["Je lutte contre", "Je me sens submergé.e", "Je les observe sans jugement", "Je les analyse calmement", "J'essaie de les ignorer"],
      feedback: [
        "Lutter contre les pensées intrusives peut les renforcer. Essaye de les accueillir sans résistance, comme une observation de nuages qui passent dans le ciel.",
        "Se sentir submergé.e par ses pensées est normal. Prends du recul en respirant profondément et en te rappelant que les pensées ne définissent pas qui tu es.",
        "Observer ses pensées sans jugement est une excellente pratique ! Cela montre que tu développes une relation saine avec ton esprit.",
        "Analyser calmement tes pensées intrusives te permet de comprendre leur origine. Évite juste de trop intellectualiser.",
        "Ignorer les pensées intrusives peut les renforcer. Ca dépend de ton état d'esprit du moment, soit tu arrives vraiment à lâcher prise, soit il faudrait les observer avec bienveillance."
      ],
    },
    {
      question: "Comment décrirais-tu le TON de ton dialogue intérieur la plupart du temps ?",
      labels: ["Très critique", "Neutre", "Encourageant", "Curieux", "Compassionnel"],
      feedback: [
        "Un dialogue intérieur très critique peut miner ta confiance. Essaye de te parler comme à un ami proche, avec bienveillance et compréhension.",
        "Un ton neutre est un bon point de départ. Pourquoi ne pas y ajouter des mots encourageants pour renforcer ta motivation et ton estime de toi ?",
        "Avoir un dialogue intérieur encourageant est une force ! Cela t'aide à surmonter les défis et à rester motivé.e.",
        "Un ton curieux montre que tu t'intéresses à toi-même et à tes pensées. C'est une excellente base pour apprendre et évoluer.",
        "Un dialogue intérieur compassionnel est un signe de grande maturité émotionnelle. Tu fais preuve d'une belle bienveillance envers toi-même !"
      ],
    },
  ];

  const [answers, setAnswers] = useState(Array(questions.length).fill(0));

  // Gestion du changement de valeur du slider
  const handleSliderChange = (value) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = value;
    setAnswers(newAnswers);
  };

  // Passer à la question suivante
  const handleNextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  // Revenir à la question précédente
  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  // Soumettre le questionnaire
  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  // Récupérer la question actuelle et ses détails
  const currentQuestionData = questions[currentQuestion];
  const currentAnswer = answers[currentQuestion] || 0;

  return (
    <div className="questionnaire">
      {isSubmitted ? (
        <div className="recap">
          <div className="recap-content">
            {questions.map((question, index) => (
              <div key={index} className="recap-item">
                <h3>{question.question}</h3>
                <div className="recap-response">
                  <span className="recap-label">
                    {question.labels[answers[index]]}
                  </span>
                  <p className="recap-feedback">
                    {question.feedback[answers[index]]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <h2>{currentQuestionData.question}</h2>

          {/* Slider */}
          <div className="slider-container">
            <input
              type="range"
              min="0"
              max={currentQuestionData.labels.length - 1}
              value={currentAnswer}
              onChange={(e) => handleSliderChange(parseInt(e.target.value))}
            />
            <div className="slider-labels">
              {currentQuestionData.labels.map((label, index) => (
                <span key={index}>{label}</span>
              ))}
            </div>
          </div>

          {/* Feedback en fonction de la réponse */}
          <div className="feedback">
            <p>{currentQuestionData.feedback[currentAnswer]}</p>
          </div>

          {/* Boutons de navigation */}
          <div className="navigation-buttons">
            <button onClick={handlePreviousQuestion} disabled={currentQuestion === 0}>
              Précédent
            </button>
            {currentQuestion < questions.length - 1 ? (
              <button onClick={handleNextQuestion}>Suivant</button>
            ) : (
              <button onClick={handleSubmit}>Terminer</button>
            )}
          </div>
        </>
      )}
      </div>
  );
};

export default Questionnaire;