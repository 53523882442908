import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import CheckpointButton from '../CheckpointButton.js';
import Questionnaire from '../animations/questionnaire.js';
import AnimatedH2 from '../animations/animatedH2.js';

function Chapitre11Saimer() {
  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();
  
  return (
    <>
        <h1 className='chapitre'>Chapitre 11: Se respecter</h1>

        <p>
        Nous sommes de sacrés maîtres en auto-sabotage. Nous sommes en fait notre pire ennemi.
        Et notre meilleur ami aussi !
        </p>

        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
        <p className="animatable">    
        Apprendre à se respecter, c'est apprendre à prendre conscience de ces moments où tu te dévalorises, où tu cherches à nourrir le passé plutôt que de vivre le présent, ou encore où tu penses décider d'une voie par peur de souffrir. 
        </p>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
        <p className="gold animatable">
        Prendre conscience, c'est 90% du travail. Si tu ne vois pas ton fonctionnement mental, tu ne pourras qu'en subir ses conditionnements. 
        </p>
        </animated.div>

        <p>
        Ensuite, le respect c'est un acte du cœur. Plus tu as cette capacité empathique, plus suite à la conscientisation d'un manque de respect ou autre, tu peux te permettre de lâcher-prise, pardonner, et faire confiance à la justesse intérieure. C'est ce qui te guide. 
        <br />Parfois, on parle d'intuition, parfois de ressenti, ou bien de sentiments. En général, tu te respectes autant que tu respectes les autres. Il y a, bien sûr, des situations où l'on respecte plus ou moins, selon nos croyances etc. 
        Il est bon de savoir se remettre en question pour plus de flexibilité. 
        </p>

        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
        <p className="animatable">
            “ Ce n'est pas un indice de bonne santé d'être bien adapté à une société profondément malade ” - Jiddu Krishnamurti
        </p>
        </animated.div>

        <AnimatedH2 className="subsection-title">Comment est ton dialogue intérieur ?</AnimatedH2>

        <Questionnaire />
        <p><br /><br />
        On a tous un critique intérieur. Mais que ce passe t-il si on laisse ces pensées aller et venir ?<br /><br />
        Ca dépend de notre conscience sur l'instant. Est-ce qu'on lâche prise totalement à propos ?
        Est-ce que ça nous touche, nous fait réagir ? 
        Notre but ici, va être de devenir <b>cet(te) ami(e), ce parent, ou cette personne bienveillante</b> qui vient nous guider à propos de ce discours intérieur.
        </p>
        <p>
        Parce que face à des pensées négatives, déplaisantes, on peut être trigger (déclenché) et poursuivre ce fil comme une pelotte de laine.
        Se guider en tant qu'ami(e) ou parent bienveillant, ça peut se faire <u> par remplacer la pensée par une autre :</u> comme par une pensée positive qui te ramène à ce que tu souhaites penser réellement de toi.
        Ca peut aussi se faire par le fait de <em><b>sourire intérieurement</b></em>, s'accorder un silence intérieur face à ces pensées.<br /><br />
        Ca peut être fait comme un lâcher-prise justement !

        Du coup, tu l'auras compris le but est d'être compatissant envers soi-même, de diriger son mental.
        Comment veux-tu avoir des pensées de plus en plus claires et positives si tu te délaisses
        comme un jardinier délaisserait son jardin ?
        </p>

        <AnimatedH2 className="subsection-title">Tous génie ?</AnimatedH2>
        <p>
        D'après Albert Einstein  <span className="bold gold">« Tout le monde est un génie. Mais si vous jugez un poisson sur ses capacités à grimper à un arbre, il passera toute sa vie à croire qu'il est stupide ».</span><br /><br />
        </p>
        <p>
        Un singe sera excellent pour grimper à un arbre, l'éléphant fort pour soulever de lourdes charges, le poisson excellent pour nager, l'oiseau maître dans les airs, mais effectivement, il est bon de s'accepter tel qu'on est et de voir ses qualités pour les incarner du mieux qu'on peut.
        Ce que dit Krishnamurti ramène bien à notre sujet. Mais, effectivement, c'est pas forcément simple, sachant qu'il est question de notre relation aux autres. On aime être accepté. Mais, <b>être accepté n'est-ce pas s'accepter ?</b> Car si tu t'acceptes, est-ce que tu auras peur de la non considération des autres ? C'est pas facile, je sais. Ce qui est le plus difficile, c'est sûrement d'être “accepté par les autres” et de se sentir rejeté par soi-même.
        </p>
        
        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
            <p className="animatable">
                ***
            </p>
        </animated.div>
        <p>
        On peut se confondre dans un personnage car on s'identifie à lui. Parfois avec le regard des autres, on finit par voir qu'on paraît bien en tant que tel “personnage” (façon d'être, trait de caractère...), et c'était peut-être une part de nous qui a bien agit au travers de notre personnalité... Mais, il arrive qu'on s'y identifie et que cela devienne un masque. 
        Alors qu'en vérité nous ne sommes pas figés dans une part de personnalité.<br /><br />
        Parfois, il faut s'aimer suffisamment pour dépasser cette image que les gens ont de nous. Car notre vrai Nous n'a que faire d'une envie de rester figer dans une personnalité qui n'est pas nous. On a besoin de rester soi-même, au présent. Tout est toujours en mouvement et il est bon de savoir être. C'est-à-dire pouvoir rester libre et savoir rester aligné. <br /><br /><br />
        </p>

        <h3>Percevoir en autrui son "soi supérieur" plutôt que l'identité à laquelle il ou elle est attaché(e).</h3>

        <p>
        Autrement dit, on pourrait voir en l'autre sa puissance plutôt que le manque d'estime ou de confiance en soi de cette même personne. Ça pourrait être aussi : percevoir sa vulnérabilité comme sa grandeur.
        C'est incroyable de pouvoir être ainsi car se montrer vulnérable (et non faible), c'est être assez conscient et mature émotionnellement pour lâcher prise et exprimer des parts de soi qui nous permettent de grandir plutôt que d'être encore dans d'ancien schémas de survie.<br /><br />
        Si tu vois ces choses positives (vulnérabilité, puissance, qualité...) en l'autre, il y a de fortes chances que tu les perçoives de même en toi.
        Rabaisser quelqu'un ou le rendre moins puissant à travers ton regard, c'est ne pas voir ta propre puissance ou grandeur.<br /><br />
        Par exemple, victimiser une personne, c'est un discours qui inconsciemment nourrit l'idée de son propre statut de victime dans la vie. Et nous, on veut pas de ça. 
        D'où l'importance (de faire l'effort) d'ajuster sa perception face à quelqu'un.
        Voir l'être puissant qu'on a en face de nous, c'est lui permettre de se voir ainsi, et c'est l'occasion de nourrir ce regard pour soi-même. {'<3'}
        </p>

        <p><br /><br /></p>
        <CheckpointButton coursId="Cours1" chapitreId="chapitre11" checkpointId="checkpoint1" />
        <p> <br /> </p>
    
        <div className="button">
            <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>
    </>
  );
}

export default Chapitre11Saimer;
