import React, { useEffect, useState, useRef } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from './donnees/firebase.js'; 
import { useAuth } from './AuthContext.js'; 
import Confetti from "./animations/confettis.js"

const CheckpointButton = ({ coursId, chapitreId, checkpointId }) => {
  const { userData, currentUser, updateUserData } = useAuth();
  const [isValidated, setIsValidated] = useState(false);
  const confettiRef = useRef(null);

  const success = () => {
    confettiRef.current && confettiRef.current.fire();
  };

  useEffect(() => {
    if (userData) {
      const coursProgress = userData.progress?.[coursId]?.[chapitreId] || {};
      setIsValidated(!!coursProgress[checkpointId]); 
    }
  }, [userData, coursId, chapitreId, checkpointId]);

  const handleValidation = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!currentUser) {
      console.error('Utilisateur non connecté.');
      return;
    }

    try {
      const userDocRef = doc(db, 'utilisateurs', currentUser.uid);

      await updateDoc(userDocRef, {
        [`progress.${coursId}.${chapitreId}.${checkpointId}`]: true,
      });

      const updatedProgress = {
        ...userData.progress,
        [coursId]: {
          ...userData.progress?.[coursId],
          [chapitreId]: {
            ...userData.progress?.[coursId]?.[chapitreId],
            [checkpointId]: true,
          },
        },
      };

      updateUserData({ ...userData, progress: updatedProgress });
      setIsValidated(true);
      success();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du checkpoint :', error);
    }
  };

  return ( 
    <>
    <Confetti ref={confettiRef} />
    <button
      type="button"
      className="checkpoint-button"
      onClick={handleValidation}
      disabled={isValidated}
      aria-label={isValidated ? 'Checkpoint déjà validé' : 'Valider ce checkpoint'}
    >
      {isValidated ? '✅ Apprentissage validé' : 'J\'ai bien compris, on avance ! 🔥'}
    </button>

    {isValidated ? <p style={{ textAlign: 'center' }}>Récapitulatif dans ton profil.</p> : null}
    </>
  );
};

export default CheckpointButton;
